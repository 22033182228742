import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchServices(ctx, data) {
      return useJwt.getServices(data).then(response => response);
    },
    createService(ctx, data) {
      return useJwt.createService(data).then(response => response);
    },
    updateService(ctx, data) {
      return useJwt.updateService(data).then(response => response);
    },
    deleteService(ctx, id) {
      return useJwt.deleteService(id).then(response => response);
    },
  },
};
